
          @use "sass:math";
          @import "~@/assets/scss/abstract.scss";
        











div {
  ::v-deep {
    img {
      max-width: 100%;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }
}
